<template>
  <v-row v-if="loading" align="center" justify="center" class="fill-height">
    <v-progress-circular
      :size="60"
      :width="5"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </v-row>

  <v-container v-else class="refund-transaction-details">
    <v-card max-width="740" class="mx-auto px-6 pb-6 pt-4 overflow-hidden">
      <div class="d-block text-center mb-3">
        <img width="60" :src="data.logo" />
      </div>

      <div class="text-center mb-1">
        <span class="font-medium-20 gray13--text me-1">{{
          data.cryptoAmountString
        }}</span>
        <span class="font-medium-20 gray13--text">{{ data.iso }}</span>
      </div>

      <div class="mb-1 d-flex align-center justify-center">
        <span class="font-medium-20 gray8--text me-1">≈</span>
        <span class="font-medium-14 gray8--text"
          >${{ data.localFiatCurrencyString }}</span
        >
      </div>

      <div class="text-center">
        <v-chip
          small
          :color="statusColors.bg_color"
          :text-color="statusColors.text_color"
        >
          <span class="font-regular-10">{{ data.transactionStatusText }}</span>
        </v-chip>
      </div>

      <div class="d-sm-flex justify-sm-space-between text-center my-4">
        <div class="font-regular-12 gray9--text">
          {{
            $t(
              "panel.transactionsPage.refundTransactions.refundTransactionDetails.dateAndTime"
            )
          }}
        </div>

        <span class="gray12--text">
          {{ data.transactionTime | $renderDate }}
        </span>
      </div>
      <v-divider></v-divider>

      <div class="d-sm-flex justify-sm-space-between text-center my-4">
        <div class="font-regular-12 gray9--text">
          {{
            $t(
              "panel.transactionsPage.refundTransactions.refundTransactionDetails.riverPayPaid"
            )
          }}
        </div>

        <span class="font-regular-14 gray12--text">{{
          data.riverPayPaid
        }}</span>
      </div>
      <v-divider></v-divider>

      <div class="d-sm-flex justify-sm-space-between text-center my-4">
        <div class="font-regular-12 gray9--text">
          {{
            $t(
              "panel.transactionsPage.refundTransactions.refundTransactionDetails.networkFee"
            )
          }}
        </div>

        <span class="font-regular-14 gray12--text">{{
          data.networkFeeString
        }}</span>
      </div>
      <v-divider></v-divider>

      <div class="d-sm-flex justify-sm-space-between text-center my-4">
        <div class="font-regular-12 gray9--text">
          {{
            $t(
              "panel.transactionsPage.refundTransactions.refundTransactionDetails.to"
            )
          }}
        </div>

        <span
          class="font-regular-14 gray12--text text-truncate walletAddress d-inline-block"
          >{{ data.walletAddress }}</span
        >
      </div>
      <v-divider></v-divider>

      <div
        class="d-sm-flex justify-sm-space-between my-4 text-center align-center"
      >
        <div class="font-regular-12 gray9--text text-no-wrap">
          {{
            $t(
              "panel.transactionsPage.refundTransactions.refundTransactionDetails.businessName"
            )
          }}
        </div>

        <span class="font-regular-14 gray12--text text-right">{{
          data.businessName
        }}</span>
      </div>
      <v-divider></v-divider>

      <div class="d-sm-flex justify-sm-space-between text-center my-4">
        <div class="font-regular-12 gray9--text">
          {{
            $t(
              "panel.transactionsPage.refundTransactions.refundTransactionDetails.transactionId"
            )
          }}
        </div>

        <span class="gray12--text text-truncate">{{ data.transactionId }}</span>
      </div>
      <v-divider></v-divider>

      <div class="d-sm-flex justify-sm-space-between text-center my-4">
        <div class="font-regular-12 gray9--text">
          {{
            $t(
              "panel.transactionsPage.refundTransactions.refundTransactionDetails.transactionHash"
            )
          }}
        </div>

        <span class="gray12--text text-truncate">{{
          data.transactionHash
        }}</span>
      </div>
      <v-divider></v-divider>

      <div class="d-sm-flex justify-sm-space-between text-center my-4">
        <div class="font-regular-12 gray9--text">
          {{
            $t(
              "panel.transactionsPage.refundTransactions.refundTransactionDetails.viewExplorer"
            )
          }}
        </div>

        <div>
          <a :href="data.explorerLink" target="_blank" class="font-semiBold-14"
            >{{
              $t(
                "panel.transactionsPage.refundTransactions.refundTransactionDetails.viewExplorer"
              )
            }}
            <v-icon size="16" class="ms-2">$shareArrow</v-icon>
          </a>
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { ReportService } from "@/services";
import renderDate from "@/mixins/renderDate";

export default {
  name: "refund-transaction-details",
  components: {},
  props: ["id"],
  mixins: [renderDate],
  data() {
    return {
      loading: false,
      data: {},
    };
  },
  computed: {
    statusColors() {
      const data = this.$t("panel.stateStatuses").find(
        (elem) => elem.status === this.data.transactionStatus
      );
      if (data) return data;
      else
        return this.$t("panel.stateStatuses").find(
          (elem) => elem.status === "OTHER"
        );
    },
  },
  mounted() {
    this.getTrxDetails();
  },
  methods: {
    getTrxDetails() {
      this.loading = true;
      ReportService.getRefundTrxById(this.id)
        .then((res) => {
          this.data = res.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "RefundTransactionDetails";
</style>
